var dispatch = {
	focusEffect: function()  {
		if (!String.prototype.trim) {
			(function()  {
				var c = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
				String.prototype.trim = function()  {
					return this.replace(c, "")
				}
			})()
		}

		[].slice.call(document.querySelectorAll(".input__field")).forEach(function (c) {
			if (c.value.trim() !== "") {
				c.parentNode.classList.add('input--filled');
			}
			c.addEventListener("focus", a);
			c.addEventListener("blur", b)
		});

		function a(e) {
			e.target.parentNode.classList.add('input--filled');
		}

		function b(e) {
			if (e.target.value.trim() === "") {
				e.target.parentNode.classList.remove('input--filled');
			}
		}
	},

	common: function()  {
		var scrollTop = parseInt($(window).scrollTop());
		if (scrollTop !== 0) {
			$("#mainHeader").addClass("sticky");
			$('#backtoTop').show();
		}

		$(window).scroll(function()  {
			var scrollTop = parseInt($(window).scrollTop());
			if (scrollTop > 0) {
				$("#mainHeader").addClass("sticky");
				$('#backtoTop').show();
			} else {
				$("#mainHeader").removeClass("sticky");
				$("#mainHeader").removeAttr("style");
				$('#backtoTop').hide();
			}
		});

		$('#backtoTop').click(function()  {
			$('body, html').animate({ scrollTop: 0 }, 600);
		});

		// Scroll Height Calculator
		$(window).scroll(function()  {
			var wintop = $(window).scrollTop(), docheight = $('html').height(), winheight = $(window).height();
			var totalScroll = (wintop / (docheight - winheight)) * 100;
			$(".scrollAnimate").css("width", totalScroll + "%");
		});

		// For bootstrap Modals only
		(function()  {
			var isForgotModal = false;

			$('.open_OTP_CreateAccount').click(function()  {
				$('.modal').modal('hide');
				isForgotModal = true;
			});

			// Default Modal Show Function
			$('.modal').on('show.bs.modal', function()  {
				$('#mainHeader').css({ 'right': getScrollBarWidth() })
			});

			// Default Modal Hide Function
			$('.modal').on('hidden.bs.modal', function (e) {
				$('#mainHeader').css({ 'right': 0 });

				if (isForgotModal) {
					$('#modal_OTP_CreateAccount').modal('show');
				}
				isForgotModal = false;
			});
		})();

		// Toggle view of Filter box
		(function()  {
			$('[data-filterView="toggle"]').click(function()  {
				var target = this.dataset.targetid;
				this.className += ' show';

				if (this.textContent == 'Show Filter') {
					document.querySelector(target).closest('.showCaseBox').className += ' is-filterShow';
					this.textContent = 'Hide Filter';
					this.classList.remove('show');
				} else {
					document.querySelector(target).closest('.showCaseBox').classList.remove('is-filterShow');
					this.textContent = 'Show Filter';
					this.classList.add('show');
				}
			});
		})();

		(function()  {
			if ($('.searchHandle')) {
				function closeSearch() {
					$('.header__logo, .primaryMenu, .headerLinks').removeClass('fadeOut');
					$('.searchBar').removeClass('fadeIn');
					$('.header__overlay').fadeOut(500);
					$('.autoSuggestion ul').removeClass('active');
				}

				$('.searchHandle').click(function()  {
					$('.header__logo, .headerLinks, .primaryMenu').addClass('fadeOut');
					$('.searchBar').addClass('fadeIn');
					$('.header__overlay').fadeIn(500);
					$('#searchInput').focus();
					$('.autoSuggestion ul').addClass('active');
				});

				$('#searchClose, .header__overlay').click(function()  {
					closeSearch();
				});

				$(document).on('keyup', function (e) {
					if (e.keyCode === 27 && e.which === 27) {
						closeSearch();
					}
				})
			}
		})();

		// Mobile Menu
		$('.menuHandle').click(function (e) {
			e.preventDefault();
			$(this).find('img').attr('src', function (index, attr) {
				var newAttr = attr.split('/');
				var curImg = newAttr[newAttr.length - 1].split('.')[0];
				var path = newAttr.slice(0, newAttr.length - 1).join('/');

				if (curImg == 'menu') {
					$(this).closest('.primaryMenu').find('#toggleMenu').slideDown(400);
					return path + '/close.svg'
				} else {
					$(this).closest('.primaryMenu').find('#toggleMenu').slideUp(300);
					return path + '/menu.svg'
				}
			});
		});

		// Mobile Sidebar Menu
		$("#sidebarMenuOpen").click(function() {
			$("#sidebarMenu").addClass("showMenu");
		});
		
		$("#sidebarMenuClose").click(function()  {
			$("#sidebarMenu").removeClass("showMenu");
		});

		// Mobile dropdown toggle
		$('.dropdown-handle').click(function()  {
			var siblings = $(this).parents('li').siblings();
			siblings.find('.dropdown').slideUp();
			siblings.find('.dropdown-handle').removeClass('active');
			$(this).parents('li').find('.dropdown').slideToggle();
			$(this).toggleClass('active');
		});

		// Dashboard Sidebar toggle
		$('.dash-toggle').click(function()  {
			$(this).parent('.userDashboard__leftNav').toggleClass("open");
			$('.footer').toggleClass("dash-opened");
		});
	},

	homePage: function()  {
		$('#WhatCustomerSaySlider').slick({
			dots: true,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 2500,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
		});

		$("#headerMenuOpen").click(function() {
			$(".primaryFixedMenu").addClass("show");
			$("body").css("overflow: hidden");
		});

		$("#primaryFixedMenuClose").click(function() {
			$(".primaryFixedMenu").removeClass("show");
		});
	},

	menuPage: function()  {
		$('#discoverMainMenuTopSlider').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			adaptiveHeight: true,
			asNavFor: '#discoverMainMenuBottomSlider',
			responsive: [ {
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			}, {
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			}, {
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				}
			} ]
		});
		$('#discoverMainMenuBottomSlider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			fade: true,
			adaptiveHeight: true,
			asNavFor: '#discoverMainMenuTopSlider',
		});
	},

	init: function()  {
		this.focusEffect();
		this.common();
		this.homePage();
		this.menuPage();
	}
};

$(document).ready(function() {
	dispatch.init();

	// $('body').addClass('lockScroll');
	// $('body').css({ 'paddingRight': getScrollBarWidth() });

	// $(window).load(() => {
	// 	if (!document.querySelector('.siteLoaderWrap')) return;
	// 	setTimeout(() => {
	// 		$('body').removeClass('lockScroll');
	// 		$('body').css({ 'paddingRight': 0 });
	// 		$('.siteLoaderWrap').hide();
	// 	}, 0);
	// });
	
	$(window).scroll(function () {
		if ($(this).scrollTop() > 0) {
			$('#discoverMainMenuTop > .container').addClass('fixedMainMenu');
		} else {
			$('#discoverMainMenuTop > .container').removeClass('fixedMainMenu');
		}
	});
});